var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Kas"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.allowCreate() || _vm.allowUpdate() ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.add();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Kas ")], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6",
      "align": "right"
    }
  }, [_c('h3', {
    staticClass: "text-info",
    attrs: {
      "align": "right"
    }
  }, [_vm._v("Saldo : Rp " + _vm._s(_vm.formatRupiah(_vm.getNominal(_vm.items))))]), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form "
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formkas"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArchiveIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "ml-50 mb-2"
  }, [_vm._v("Data Kas")])], 1), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal",
      "label-for": "v-tanggal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "tanggal"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }
          },
          model: {
            value: _vm.form.tanggal,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2642788762)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Kas",
      "label-for": "v-nama"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required|min:2",
      "name": "kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama",
            "placeholder": "Isi Nama Kas"
          },
          model: {
            value: _vm.form.nama,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama", $$v);
            },
            expression: "form.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3852778882)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "v-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required|min:2",
      "name": "keterangan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-keterangan",
            "placeholder": "Isi Keterangan"
          },
          model: {
            value: _vm.form.keterangan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "keterangan", $$v);
            },
            expression: "form.keterangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 131864206)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Tambah ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(avatar)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.value
          }
        })];
      }
    }, {
      key: "cell(saldo)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.saldo >= 0 ? "Rp " + _vm.formatRupiah(item.saldo) : "Rp " + _vm.formatRupiah(item.saldo)))])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Rincian',
            expression: "'Rincian'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/kas-alur/".concat(row.item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1), _vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.detailmodal.id,
      "title": _vm.detailmodal.title,
      "ok-only": ""
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.detailmodal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }